import React, { useState , useEffect, useRef  } from "react";


import Input from "components/shared/Inputs/Inputs";
import SearchTable from "components/shared/SearchTable/SearchTable";
import { useNavigate, useParams } from "react-router-dom";

import api from "../../services/api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Container, Row,Modal, Button,
  ModalBody,
  ModalHeader } from 'reactstrap';

import AuthService from "../../services/Auth.service";
import ReactInputMask from "react-input-mask";


const Condominios = () => {
  const initialState = {
    number: "",
    desc: "",
    model: "",
    status: "",
  };


  const [showRazao, setShowRazao] = useState(false);

  const [userData, setUserData] = useState({})

  const [load, setLoad] = useState(true);

  const [modal, setModal] = useState(false);
  const user = AuthService.getCurrentUser();
  const [validDelete, setValidDelete] = useState(true);


  const [password, setPassword] = useState('');
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === 'token') {
      setPasswordEntered(true);
    } else {
      alert('Senha inválida'); // You can replace alert with a more sophisticated error handling approach
    }
  };







  let navigate = useNavigate();


  const toggle = () => setModal(!modal);

  const [modalType, setModalType] = useState("")

  let checkempresa = null;
  let razao = null;
  if(user != null){
  if(user.empresa[0] != undefined){
    checkempresa = user.empresa[0].OID_EMPRESA
    razao = user.empresa[0].NM_RAZAO_SOCIAL;
  }
  }

  const statusArray = [
    { id: "", status: "Selecione..." },
    { id: "S", status: "Ativo" },
    { id: "N", status: "Inativo" },
  ];



  const [search, setSearch] = useState(initialState);
  const [values, setValues] = useState([]);
  const [showList, setShowList] = useState(false);
  const [PendingRadioValue, setPendingRadioValue] = useState(null);
  const [data, setData] = useState([])
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
		api.get('/empresa/').then((response) => {
			let arr = [];
			arr = formatArray(response.data);
      console.log(arr)
			setData(arr)
			setValues(arr);
      setFormattedData(arr)
		});
	}, []);

  const [filterCondominio, setfilterCondominio] = useState("")
  const [filterEndereco, setfilterEndereco] = useState("")
  let uniqueEmpresas = [];

  if (formattedData.length > 0) {
    uniqueEmpresas = [...new Set(formattedData.map(info => info.razao))];
  }

  let uniqueEnd = [];

  if (formattedData.length > 0) {
    uniqueEnd = [...new Set(formattedData.map(info => info.endereco))];
  }

   let filteredShowInfos = [];


  // if (formattedData.length > 0) {
  //   filteredShowInfos = filterCondominio
  // ? formattedData.filter(info =>
  //     info.razao.toLowerCase().includes(filterCondominio.toLowerCase())
  //   )
  // : formattedData;
  // }

  // if (formattedData.length > 0) {
  //   filteredShowInfos = filterCondominio
  // ? formattedData.filter(info =>
  //     info.endereco.toLowerCase().includes(filterCondominio.toLowerCase())
  //   )
  // : formattedData;
  // }

  if (formattedData.length > 0) {
    if (filterCondominio.length > 0) {
      if(filterCondominio != "Todos"){
      filteredShowInfos = formattedData.filter(info =>
        info.razao.toLowerCase().includes(filterCondominio.toLowerCase())
      );
    }
      else{
        setfilterCondominio("")
        filteredShowInfos = formattedData;
      }
    } else if (filterEndereco.length > 0 ) {
      console.log(filterEndereco)
      if(filterEndereco != "Todos"){
      filteredShowInfos = formattedData.filter(info =>
        info.endereco.toLowerCase().includes(filterEndereco.toLowerCase())
      );
      }
      else{
        setfilterEndereco("")
        filteredShowInfos = formattedData;
      }
    } else {
      // Handle the case where neither input has a value
      filteredShowInfos = formattedData;
    }
  }


  



  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!search.number && !search.desc && !search.model && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha ao menos um campo para fazer uma pesquisa",
      });
      return;
    }

    if (!search.number && (search.desc || search.model) && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha o campo Status para fazer uma pesquisa sem Número Hidrômetro",
      });
      return;
    }

    let body = {
      NR_HIDROMETRO: search.number,
      DS_DESCRICAO_HIDROMETRO: search.desc,
      DS_MODELO_HIDROMETRO: search.model,
      TP_ATIVO: search.status,
    };

    api
      .post("/hydrometers/filter", body)
      .then((response) => {
        let arr = formatArray(response.data);
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 1000,
          });
          arr.map((elem) => {
            if (elem.status === "S") {
              elem.status = "Ativo";
            }
            if (elem.status === "N") {
              elem.status = "Inativo";
            }
          });

          console.log(arr);
          setValues(arr);
          setSearch(initialState);
          setShowList(true);
          setFormattedData(arr)
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          text: "Erro Tente Novamente!",
        });
        setShowList(false);
      });
  };

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        id: elem.OID_EMPRESA,
        cnpj: elem.NR_CNPJ,
        razao: elem.NM_RAZAO_SOCIAL,
        cep: elem.CD_CEP,
        telefone: elem.NR_TELEFONE,
        endereco: elem.DS_LOGRADOURO+", "+elem.NR_LOGRADOURO+", "+elem.DS_BAIRRO+".",
        checked: elem.TP_ENVIO === "S" ? true : false,
        log: elem.DS_LOGRADOURO,
        nr: elem.NR_LOGRADOURO,
        bairro: elem.DS_BAIRRO,
        diaLeitura: elem.NR_DIA_LEITURA,
        leituraStatus: verificaleitura(elem.NR_DIA_LEITURA)

      });
    });
    return arr;
  };

//   const handleRadioChange = (id, value) => {
//   // Assuming you have an array of data called 'formattedData'
//   setFormattedData(prevData =>
//     prevData.map(item => (item.id === id ? { ...item, checked: value } : item))
//   );
// };

const warning = (value) => {
  // Assuming you have an array of data called 'formattedData'

  
  if(value === true){
    setModalType("sim"); 
  }
  else{
    setModalType("nao");
  }

  setPendingRadioValue(value);


  
  toggle();

  
};

const handleRadioChangecancela = (id, value) => {
  // Assuming you have an array of data called 'formattedData'
  toggle();
  

  setFormattedData(prevData =>
    prevData.map(item => {
      if (item.id === id) {
        console.log(`Changing radio with id ${id} to value ${value}`);
        return { ...item, checked: value };
      } else {
        return item;
      }
    })
  );
  // setPendingRadioValue(null);
};


const handleClick = arr => {
  setShowRazao(true)
}

const [editModeRow, setEditModeRow] = useState(null);
const [editModeRowEnd, setEditModeRowEnd] = useState(null);
const [editedName, setEditedName] = useState('');
const [editedCnpj, setEditedCnpj] = useState('');
const [editedLog, setEditedLog] = useState('');
const [editedNrLog, setEditedNrLog] = useState('');
const [editedBairro, setEditedBairro] = useState('');
const [editedDiaLeitura, setEditedDiaLeitura] = useState('');
const [cores, setCores] = useState({'red': 'red', 'green': '#7CFC00'});
const handleSave = (arr) => {
  setEditModeRow(null); // Exit edit mode
  
  // Perform save logic here
  let data = {
  
    NR_CNPJ: editedCnpj,
    NR_LOGRADOURO: editedNrLog,
    DS_LOGRADOURO: editedLog,
    DS_BAIRRO: editedBairro,
    NM_RAZAO_SOCIAL: editedName,
    NR_DIA_LEITURA: editedDiaLeitura != "" ? editedDiaLeitura : null
  }

if(editedDiaLeitura != null){
  if(editedDiaLeitura > 31 || editedDiaLeitura < 1){
    Swal.fire({
      icon: "warning",
      title: "Erro!",
      text: "O dia de leitura deve ser um número entre 1 e 31",
    });
    return;
  }
}
  api.put("/empresa/"+arr.id, data ).then(() => {
      Swal.fire({
        icon: "success",
        title: "Dado(s) Alterado(s)!",
      })
        .then(() => {
          navigate("/condominios");
          window.location.reload(true)
        })
        .catch(() => {
          Swal.fire({
            icon: "warning",
            title: "Erro!",
          });
        });
    });

};

  // Function to handle edit button click
  const handleEditClick = (arr) => {
    setEditedName(arr.razao);
    setEditedCnpj(arr.cnpj);
    setEditedLog(arr.log);
    setEditedNrLog(arr.nr);
    setEditedBairro(arr.bairro);
    setEditedDiaLeitura(arr.diaLeitura);
    setEditModeRow(arr); // Set the row to edit mode
  };

  const handleEditClickcnpj = (arr) => {
    setEditedName(arr.razao);
    setEditedCnpj(arr.cnpj);
    setEditedLog(arr.log);
    setEditedNrLog(arr.nr);
    setEditedBairro(arr.bairro);
    setEditModeRow(arr); // Set the row to edit mode
  };

  // const handleEditClickend = (arr) => {
  //   setEditedName(arr.razao);
  //   setEditedCnpj(arr.cnpj);
  //   setEditedLog(arr.log);
  //   setEditedNrLog(arr.nr);
  //   setEditedBairro(arr.bairro);
  //   setEditModeRowEnd(arr); // Set the row to edit mode
  // };

  // Function to handle save button click
  

useEffect(() => {
  const interval = setInterval(() => {
    setCores(prevCores => {
      if (prevCores.red === '') {
        return {'red': 'red', 'green': '#7CFC00'};
      } else {
        return {'red': '', 'green': ''};
      }
    });
  }, 2000);

  return () => clearInterval(interval);
}, []);
const verificaleitura = (diaLeitura) => {
  const hoje = new Date();
  const diaAtual = hoje.getDate();
  
  let diasRestantes;
  if (diaLeitura >= diaAtual) {
    diasRestantes = diaLeitura - diaAtual;
  } else {
    const ultimoDiaMes = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).getDate();
    diasRestantes = (ultimoDiaMes - diaAtual) + parseInt(diaLeitura);
  }

  if (diasRestantes <= 2) {
    return "red";
  } else if (diasRestantes > 2 && diasRestantes < 5) {
    return "green"; 
  } else {
    return "none";
  }
}

const handleRadioChangesim = (id, value) => {
  toggle();
  // Assuming you have an array of data called 'formattedData'

  // setModalType("email"); 
  // toggle();

  // let data = {
    
        
  //   TP_ENVIO: value === true ? "S" : "N"
  // }
  // api.put("/empresa/"+id, data )

  setFormattedData(prevData =>
    prevData.map(item => {
      if (item.id === id) {
        console.log(`Changing radio with id ${id} to value ${value}`);
        return { ...item, checked: true };
      } else {
        return item;
      }
    })
  );
  // setPendingRadioValue(null);
};

const handleRadioChange = (id, value) => {
   toggle();
  // Assuming you have an array of data called 'formattedData'

  // setModalType("email"); 
  // toggle();

  let data = {
    
        
    TP_ENVIO: value === true ? "S" : "N"
  }
  api.put("/empresa/"+id, data )

  setFormattedData(prevData =>
    prevData.map(item => {
      if (item.id === id) {
        console.log(`Changing radio with id ${id} to value ${value}`);
        return { ...item, checked: value };
      } else {
        return item;
      }
    })
  );
  // setPendingRadioValue(null);
};

  

  const handleCheckboxChange = (id,checked) => {
    if (checked === "S"){
    setFormattedData((prevData) =>
      prevData.map((item) => {
        const updatedItem = item.id === id ? { ...item,  checked: "N"} : item;
        console.log(`Updated item with id ${id}:`, updatedItem);
        return updatedItem;
      })
    );
  }
  else{
    setFormattedData((prevData) =>
      prevData.map((item) => {
        const updatedItem = item.id === id ? { ...item,  checked: "S"} : item;
        console.log(`Updated item with id ${id}:`, updatedItem);
        return updatedItem;
      })
    );

  }
    // Call your function with the updated data
    yourFunction(id);
  };

  const handleDeleteClick = async (arr) => {
    let medidores = await buscarMedidores(arr.id)
    console.log(medidores);
    if(medidores > 0){
      Swal.fire({
        icon: "warning",
        title: "Erro!",
        text: "Esta Empresa possui "+medidores+" medidores Individualizados cadastrados ",
      });
      return;
    }
    Swal.fire({
      title: 'Deseja realmente excluir este condomínio?',
      text: "Esta ação não poderá ser revertida!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6', 
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete("/empresa/"+arr.id).then(() => {
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Empresa deletada com sucesso",
            timer: 5000,
          }).then(() => {
            window.location.reload(true);
          });
        }).catch(() => {
          Swal.fire({
            icon: "error", 
            title: "Erro!",
            text: "Erro ao deletar empresa",
            timer: 1000,
          });
        });
      }
    });


}


  const yourFunction = (id) => {
    // Handle the logic based on the checkbox value and row id
    const updatedItem = formattedData.find((item) => item.id === id);
  console.log(`Checkbox clicked for row with id ${id}. Updated value:`, updatedItem.checked);
    // Add your logic here
  };

  const buscarMedidores = async (id) => {
    let body = {
      OID_EMPRESA: id
    }
    let medidores = 0
     await api.post("/hydrometers/filter/todos", body).then((response) => {
      medidores = response.data.length
    });
    return medidores
  }

  const checkboxRef = useRef("");
  const [checkbox, setCheckbox] = useState(false);
  const handleChange = (id) => {
    
    setCheckbox(checkboxRef.current.checked);
    console.log(`Checkbox clicked for row with id ${id}.`);
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Lista de Condomínios cadastrados | Syskeeper</label>

     
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">


                  <Row>
                  
                    <label className="col-md-1 col-form-label">
                      Razão
                    </label>
                    <div className="col-md-3">
                      <input className="form-control" 
                      list="datalistOptions" 
                      id="exampleDataList" 
                      placeholder="Digite para procurar..."
                      onChange={(e) => setfilterCondominio(e.target.value)}
                      value={filterCondominio}
                       />
                       {filterCondominio && (
                        <button 
                          type="button" 
                          className="btn btn-secondary clear-button" 
                          
                          onClick={() => setfilterCondominio("")}
                        >
                          Limpar
                        </button>
                      )}
                      <datalist id="datalistOptions">
                      {uniqueEmpresas
                      .filter((empresa) =>
                        empresa.toLowerCase().includes(filterCondominio.toLowerCase())
                      )
                      .map((empresa, index) => (
                        <option key={index} value={empresa}>
                          {empresa}
                        </option>
                      ))}
                      </datalist>
                    </div>
                   
                
                    <label className="col-md-1 col-form-label" style={{ paddingLeft: "4%", paddingBottom:"2%",paddingRight:"0%" }}>
                      End.
                    </label>
                    <div className="col-md-3">
                      <input className="form-control" 
                      list="datalistOptions2" 
                      id="exampleDataList2" 
                      placeholder="Digite para procurar..."
                      onChange={(e) => setfilterEndereco(e.target.value)}
                      value={filterEndereco}
                     
                       />
                       {filterEndereco && (
                        <button 
                          type="button" 
                          className="btn btn-secondary clear-button" 
                          onClick={() => setfilterEndereco("")}
                        >
                          Limpar
                        </button>
                      )}
                      <datalist id="datalistOptions2">
                      
                      {uniqueEnd
                      .filter((empresa) =>
                        empresa.toLowerCase().includes(filterEndereco.toLowerCase())
                      )
                      .map((empresa, index) => (
                        <option key={index} value={empresa}>
                          {empresa}
                        </option>
                        
                      ))}
                      </datalist>
                    </div>
                   
                    <div className="col-md-3">
                    <div className="text-sm-end">
                
                <Link to="/cadastro/condominio-cadastro">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        // onClick={handleOrderClicks}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Adicionar
                      </Button>
                      </Link>
                    </div>
                    </div>
                  </Row>


            {/* <div className="row" style={{ textAlign: "end" }}>
              
              <div className="col-6" style={{ alignSelf: "center" }}>
                <Button
                  icon="ti-user"
                  text="Cadastrar Morador"
                  link="/cadastro/moradores-cadastro"
                  
                />
              </div>
            </div> */}
           
            
           
            
          </div>
        </form>
        {/* <SearchTable
          tableHead={[
            "Nome",
            
            "",
          ]}
          tableBody={[values, 1]}
          showTable={showList}
        >
          {values?.map((maker, key) => (
            <td key={key}>
              <Link
                type="button"
                to={`/cadastro/hidrometro-update/${maker.id}`}
                color="link"
                size="sm"
                className="btn-light waves-effect waves-light"
              >
                <i className="dripicons-document-edit" />
              </Link>
            </td>
          ))}
        </SearchTable> */}
        <div className="table-responsive">
						<table className="table align-middle table-nowrap mb-0">
							<thead className="table-light">
								<tr>
									<th className="align-middle">
										CNPJ
									</th>
									<th className="align-middle">Razão Social</th>
                  <th className="align-middle">End.</th>
                  <th className="align-middle">Dia de leitura</th>

									<th className="align-middle" style={{paddingLeft:"10px"}}>CEP</th>
									
									<th className="align-middle">Status</th>
                  <th className="align-middle"></th>
                  <th className="">Envio Prefeituras</th>
                  <th className="align-middle">Ações</th>
                  <th></th>
									
								</tr>
							</thead>
							<tbody>
                {filteredShowInfos.length >0 ? 
								filteredShowInfos?.map((arr, key) => (
									<tr key={'_tr_' + key}>
										<td>
                      
                      
                    {(() => {
                      if (editModeRow === arr) {
                        // In edit mode, display an input field or any other editable component
                        return (
                          <>
                            <ReactInputMask
                              mask="99.999.999/9999-99"
                              type="text"
                              value={editedCnpj}
                              
                              onChange={(e) => setEditedCnpj(e.target.value)} // Set the state variable
                            />
                            <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => handleSave(arr)} // Pass a reference to handleSave
                        >
                          Salvar
                        </button>
                          </>
                        );
                      } else {
                        return (
                          <span className="">
                            {arr.cnpj ? arr.cnpj : "00.000.000/00001-00"}
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleEditClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                          </span>
                        );
                      }
                    })()}
                      
                      
                      
                      
                      {/* {arr.cnpj? arr.cnpj : "00.000.000/00001-00"}  */}





                    </td>
										<td> 
                      

                    {(() => {
                      if (editModeRow === arr) {
                        // In edit mode, display an input field or any other editable component
                        return (
                          <>
                            <input
                              type="text"
                              value={editedName}
                              
                              onChange={(e) => setEditedName(e.target.value)} // Set the state variable
                            />
                            <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => handleSave(arr)} // Pass a reference to handleSave
                        >
                          Salvar
                        </button>
                          </>
                        );
                      } else {
                        return (
                          <span className="">
                            {arr.razao}
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleEditClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                          </span>
                        );
                      }
                    })()}


                    
                      
                      
                      
                    
                      
                      
                      </td>
                    <td>
                      
                    {(() => {
                      if (editModeRow === arr) {
                        // In edit mode, display an input field or any other editable component
                        return (
                          <>
                            <input
                              type="text"
                              value={editedLog}
                              
                              onChange={(e) => setEditedLog(e.target.value)} // Set the state variable
                            />
                            <input
                              type="text"
                              value={editedNrLog}
                              style={{ maxWidth: "80px" }}
                              onChange={(e) => setEditedNrLog(e.target.value)} // Set the state variable
                            />
                            <input
                              type="text"
                              value={editedBairro}
                              
                              onChange={(e) => setEditedBairro(e.target.value)} // Set the state variable
                            />
                           
                        <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => handleSave(arr)} // Pass a reference to handleSave
                        >
                          Salvar
                        </button>
                          </>
                        );
                      } else {
                        return (
                          <span className="">
                            {arr.endereco}
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleEditClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                          </span>
                        );
                      }
                    })()}
                  </td>
                  <td className="text-end">
                   {(() => {
                      if (editModeRow === arr) {
                        // In edit mode, display an input field or any other editable component
                        return (
                          <>
                            <input
                              type="number"
                              value={editedDiaLeitura}
                              max={31}
                              min={1}
                              onChange={(e) => setEditedDiaLeitura(e.target.value)} // Set the state variable
                            />
                            <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => handleSave(arr)} // Pass a reference to handleSave
                        >
                          Salvar
                        </button>
                          </>
                        );
                      } else {
                       
                        return (
                          <span className="" >
                              <b style={{backgroundColor: cores[arr.leituraStatus], padding:"10px",  borderRadius:"5px"}}>{arr.diaLeitura}</b>
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleEditClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                          </span>
                        );
                      }
                    })()}
                    </td>
										<td style={{paddingLeft:"10px"}}>{arr.cep}</td>
										
										<td>
											{arr.status == null ? (
												<span className="badge bg-success">
													Cadastro Completo
												</span>
											) : (
												<span className="badge bg-danger bg-primary">
													Cadastro Incompleto
												</span>
											)}
										</td>
										{/* <td>
											<Link
												type="button"
												to={``}
												color="link"
												size="sm"
												className="btn-light waves-effect waves-light"
											>
												<i className="dripicons-document-edit" />
											</Link>
										</td> */}
                    <td><Link
                        type="button"
                        to={`/listaMoradores/${arr.id}/`}
                        color="primary"
                        size="sm"
                        className="btn btn-primary waves-effect waves-light btn-rounded"
                         >
                       Ver Moradores
                      </Link>
                        </td>
                                    {/* <td>
                          <input
                            type="checkbox"
                            id={arr.id}
                            checked={arr.checked === "S"? "checked" : null}
                             onChange={() => handleCheckboxChange(arr.id,arr.checked)}
                          />{arr.checked}
                        </td> */}
                        <td>
                  <input
                    type="radio"
                    className="btn-check"
                    name={`btnradio_${arr.id}`}
                    id={`btnradio4_${arr.id}`}
                    autoComplete="off"
                    checked={arr.checked === true}
                    // onChange={() => handleRadioChange(arr.id, true)}
                    onClick={() => { setModalType("sim"); toggle(); setUserData(arr); warning(true);}}
                    
                  />
                  <label className="btn btn-outline-secondary" htmlFor={`btnradio4_${arr.id}`}>
                     Sim
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name={`btnradio_${arr.id}`}
                    id={`btnradio5_${arr.id}`}
                    autoComplete="off"
                    checked={arr.checked === false}
                    onClick={() => { setModalType("sim"); toggle(); setUserData(arr); warning(false);}}
                    // onChange={() => handleRadioChange(arr.id, false)}
                  />
                  <label className="btn btn-outline-secondary" htmlFor={`btnradio5_${arr.id}`}>
                    Não
                  </label>
                </td>
                <td>{(() => {
                      if (editModeRow === arr) {
                        // In edit mode, display an input field or any other editable component
                        return (
                          <>
                          <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => setEditModeRow(null)} // Pass a reference to handleSave
                        >
                          Voltar
                        </button>
                        <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => handleSave(arr)} // Pass a reference to handleSave
                        >
                          Salvar
                        </button>
                          </>
                        );
                      } else {
                        return (
                          <span className="">
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleEditClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleDeleteClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-trash" style={{color: "red"}} />
                            </a>
                          </span>
                        );
                      }
                    })()}
                </td>
									</tr>
								)) : <tr><td>Nenhum Registro </td></tr>}
							</tbody>
						</table>
            <Modal isOpen={modal} toggle={toggle} style={{ paddingTop: "15%" }}>
            <ModalHeader
              toggle={toggle}
            >
              
              {modalType == 'sim' ? <>Confirmar Ação</> : <></>}
              {modalType == 'nao' ? <>Confirmar Ação</> : <></>}

            </ModalHeader>
            <ModalBody>


                {modalType == 'sim' ?
                <>
                {passwordEntered ? (
                 <>
                  <h3>Deseja Confirmar o status de envio de {userData.razao} para SIM?</h3>
                  <div className="btn-lg d-flex justify-content-center mt-3">
                  <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={() => { handleRadioChange(userData.id, true) }}
                    >
                      Confirmar
                    </Button>
                    
                    <Button
                      color="primary"
                      className="btn-lg mx-2"
                      type="submit"
                      onClick={() => { handleRadioChangecancela(userData.id, false) }}
                    >
                      Voltar
                    </Button>
                  </div>
                  </>
             ) : (

              <>
              <div className="password-input">
                <Input
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {/* <button className="confirm-button" onClick={handlePasswordSubmit} >Entrar</button> */}
                {/* <button onClick={handlePasswordSubmit}>Submit</button> */}
              </div>
              <div style={{ margin:"5px" }}>
      
              <button className="confirm-button"   onClick={handlePasswordSubmit} >Entrar</button>
      
              </div>
             
               </>
            )}


                </> : <></>
              }

              
              
              {modalType == 'nao' ?
                <>
                  {passwordEntered ? (
                 <>
                  <h3>Deseja Confirmar o status de envio de {userData.razao} para NÂO?</h3>
                  <div className="btn-lg d-flex justify-content-center mt-3">
                  <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={() => { handleRadioChange(userData.id, false) }}
                    >
                      Confirmar
                    </Button>
                    
                    <Button
                      color="primary"
                      className="btn-lg mx-2"
                      type="submit"
                      onClick={() => { handleRadioChangecancela(userData.id, true) }}
                    >
                      Voltar
                    </Button>
                  </div>
                  </>
             ) : (

              
              <>
              <div className="password-input">
                <Input
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {/* <button className="confirm-button" onClick={handlePasswordSubmit} >Entrar</button> */}
                {/* <button onClick={handlePasswordSubmit}>Submit</button> */}
              </div>
              <div style={{ margin:"5px" }}>
      
              <button className="confirm-button"   onClick={handlePasswordSubmit} >Entrar</button>
      
              </div>
             
               </>
            )}


                </> : <></>
              }
              {modalType == 'deletar' ?
                <>{load ?
                  <>Aguarde..</>
                  :
                  <>{validDelete ?
                    <><h3>Deseja deletar a empresa ?</h3><br></br>
                      <div className="btn-lg d-flex justify-content-center mt-3"                >
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                         
                        >
                          Confirmar
                        </Button>
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { toggle() }}
                        >
                          Cancelar
                        </Button>
                      </div></>
                    :
                    <> <h3>Esta Empresa possuir HD Individualizados cadastrados </h3> <br></br>
                       <div className="btn-lg d-flex justify-content-center mt-3"                >
    
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { toggle() }}
                        >
                          Entendido
                        </Button>
                      </div>
                    </>}
                  </>}

                </>

                :
                <></>}
            </ModalBody>
          </Modal>
					</div>
      </div>
    </React.Fragment>
  );
};

export default Condominios;
